.drag-and-drop-page-overlay {
  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    -o-transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &::before {
    content: 'Drop Your File(s) Here';
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--flwmn-white);
    z-index: 51;
  }

  &::after {
    content: '';
    opacity: 0.7;
    background: var(--flwmn-primary-color-900);
    z-index: 50;
  }
}
