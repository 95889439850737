.divider {
  background: var(--flwmn-neutral-100);

  &.divider-x {
    width: 100%;
    height: 0.0625rem;
    margin-block: 1.5rem;
  }

  &.divider-y {
    width: 0.0625rem;
    height: 1.375rem;
  }
}
