@use 'colors' as *;

@mixin generateColors($colors) {
  @each $name, $shades in $colors {
    @each $shade, $value in $shades {
      @if #{$shade} == 'default' {
        #{$name}: $value;
      } @else {
        #{$name}-#{$shade}: $value;
      }
    }
  }

  @each $name, $shades in $general-colors {
    @each $shade, $value in $shades {
      @if #{$shade} == 'default' {
        #{$name}: $value;
      } @else {
        #{$name}-#{$shade}: $value;
      }
    }
  }
}

:root {
  @each $theme, $colors in $themed-colors {
    .flwmn-default-theme {
      @if #{$theme} == '--flwmn-default-theme' {
        @include generateColors($colors);
      }
    }

    .theme1 {
      @if #{$theme} == '--theme1' {
        @include generateColors($colors);
      }
    }

    .theme2 {
      @if #{$theme} == '--theme2' {
        @include generateColors($colors);
      }
    }

    .theme3 {
      @if #{$theme} == '--theme3' {
        @include generateColors($colors);
      }
    }

    .theme4 {
      @if #{$theme} == '--theme4' {
        @include generateColors($colors);
      }
    }

    .theme5 {
      @if #{$theme} == '--theme5' {
        @include generateColors($colors);
      }
    }

    .theme6 {
      @if #{$theme} == '--theme6' {
        @include generateColors($colors);
      }
    }

    .theme7 {
      @if #{$theme} == '--theme7' {
        @include generateColors($colors);
      }
    }
  }
}
