.flwmn-menu {
  min-height: unset !important;
  border-radius: 0.3125rem !important;
  -webkit-box-shadow: 0px 2px 10px 0px #2222221a !important;
  box-shadow: 0px 2px 10px 0px #2222221a !important;
  // -webkit-box-shadow: 0px 11.61887264251709px 124.27999877929688px 0px #2548992b !important;
  // box-shadow: 0px 11.61887264251709px 124.27999877929688px 0px #2548992b !important;
  // -webkit-box-shadow: 0px 10px 25px rgb(232 232 232 / 50%) !important;
  // box-shadow: 0px 10px 25px rgb(232 232 232 / 50%) !important;

  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  .mat-menu-item {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    height: 2rem !important;
    line-height: 2rem !important;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    &[disabled] {
      .item-container {
        .item-icon {
          svg {
            opacity: 0.4;
          }
        }
      }
    }

    .item-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      // display: grid;
      // grid-template-columns: 1rem 1fr;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 0.625rem;

      .item-text {
        font-size: 0.875rem;
        margin-bottom: 0rem;
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }

      .item-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        svg {
          path {
            -webkit-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
          }
        }
      }
    }

    &:hover:not([disabled]) {
      background-color: var(--flwmn-primary-color-50) !important;

      .item-container {
        .item-text {
          color: var(--flwmn-primary-color);
        }

        .item-icon {
          svg {
            path {
              stroke: var(--flwmn-primary-color);
            }
          }
        }
      }
    }

    &.flwmn-mat-menu-item-danger {
      &:hover:not([disabled]) {
        background-color: var(--flwmn-error-50) !important;

        .item-container {
          .item-text {
            color: var(--flwmn-error);
          }

          .item-icon {
            svg {
              path {
                stroke: var(--flwmn-error);
              }
            }
          }
        }
      }
    }
  }
}

.webapp-navbar-dropdown-menu {
  padding: 0.5rem 1rem;
  min-width: 16.125rem !important;
  max-width: 100% !important;

  .profile-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0.3125rem;
    margin-top: 0.5rem;
    // margin-bottom: 0.5rem;
    padding: 0.625rem 1rem;
    background: var(--flwmn-neutral-50);

    .avatar-section {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 50%;
      background: var(--flwmn-white);
      width: 2rem;
      height: 2rem;
      overflow: hidden;

      img {
        -o-object-fit: contain;
        object-fit: contain;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
      }
    }

    .name-section {
      p {
        white-space: nowrap;
      }
    }
  }

  .divider {
    width: 100%;
    height: 0.0625rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: var(--flwmn-neutral-100);
  }
}

.drive-filter-dropdown-menu {
  padding: 0.75rem 0rem;
  min-width: 10rem !important;
  max-width: 100% !important;

  .menu-content {
    display: flex;
    align-items: stretch;

    .right-section {
      overflow: hidden;
      width: 0;
      flex-grow: 1;
      padding: 0 1rem 0 1.2rem;
      border-left: 0.1rem solid var(--flwmn-neutral-100);
      -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

      &-form {
        padding: 0.75rem 1rem;
      }

      &.show {
        width: 100%;
      }
    }
  }

  .divider {
    width: 100%;
    height: 0.0625rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: var(--flwmn-neutral-100);
  }

  .mat-menu-item {
    margin-bottom: 0.25rem;

    .item-container {
      .item-text {
        font-weight: 400;
      }
    }
  }
}

.flowz-task-menu {
  padding: 1.25rem 1rem;
  min-width: 23.1875rem !important;

  .task-category {
    &:not(:last-of-type) {
      border-bottom: 0.0625rem solid var(--flwmn-neutral-100);
      margin-bottom: 1rem;
    }

    .task-list {
      .task {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        cursor: pointer;

        &:hover {
          .task-icon {
            transform: scale(1.1);
          }
        }

        &-icon {
          border-radius: 0.25rem;
          padding: 0.5rem;
          border: 0.0625rem solid var(--flwmn-primary-color-100);
          background-color: var(--flwmn-primary-color-50);
          width: 2.5rem;
          height: 2.5rem;
          -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

.signing-request-response-dropdown {
  padding: 0.625rem 0.75rem;
  min-width: 15rem !important;
  max-height: 18.75rem !important;
  margin-top: 0.625rem;
  overflow-y: auto;

  .all-recipients {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0.75rem;

    .recipient {
      display: grid;
      // grid-template-columns: 1.875rem 1fr 1.25rem;
      grid-template-columns: 1fr 1.25rem;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 0.625rem;

      &:not(:last-child) {
        padding-bottom: 0.75rem;
        border-bottom: 0.00625rem solid var(--flwmn-neutral-100);
      }

      .recipient-avatar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: var(--flwmn-primary-color);
        padding: 0.125rem;
        font-size: 0.625rem;
        font-weight: 600;
        color: var(--flwmn-white);
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
      }

      .recipient-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 0.125rem;
        overflow: hidden;

        .recipient-name,
        .recipient-email,
        .recipient-action {
          margin-bottom: 0rem;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .recipient-email,
        .recipient-action {
          color: var(--flwmn-neutral-700);
        }
      }
    }
  }
}

.folder-list-menu {
  padding: 1rem 0.75rem;
  min-width: 25rem !important;
}
