@use '../abstracts/media-query' as *;
@use '../abstracts/theme' as *;

@mixin sidepane-class {
  $navbar-height: 3.75rem;
  $sidebar-width: 3.75rem;
  $sidebar-full-width: 12.5rem;
  $sidebar2-width: 18rem;

  position: fixed;
  right: 0;
  top: $navbar-height;
  bottom: 0;
  z-index: 40;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.5rem 1.25rem;
  gap: 1.5rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  resize: horizontal;
  direction: ltr; // rtl;
  overflow-y: auto;
  overflow-x: none;
  min-width: 25%;
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(34, 34, 34, 0.1);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(34, 34, 34, 0.1);
  border-right: 0.0625rem solid var(--flwmn-neutral-100);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  -o-transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;

  & > * {
    direction: ltr;
  }

  &.open {
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
  }

  &.flwmn-sidepane-left {
    left: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  &.flwmn-sidepane-right {
    right: 0;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  &.flwmn-sidepane-bottom {
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  &.flwmn-sidepane-top {
    left: 0;
    right: 0;
    top: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  &.flwmn-sidepane-md {
    width: 23.75rem;
    min-width: 21.25rem;

    @include flwmn-media-query-max(flwmn-sm) {
      width: calc(100% - $sidebar-width);
    }
  }

  &.flwmn-sidepane-lg {
    width: 46%;
    min-width: 46%;
  }

  &.flwmn-sidepane-xl {
    width: calc(100% - calc($sidebar-width + $sidebar2-width));
    min-width: 54%;
  }

  &.flwmn-sidepane-xxl {
    width: calc(100% - $sidebar-full-width);
  }

  &.flwmn-sidepane-w-full {
    width: 100%;
  }

  &.flwmn-sidepane-h-full {
    top: 0;
    z-index: 999;
  }
}

.flwmn-sidepane {
  @include sidepane-class;
}
