@use 'media-query' as *;
@use 'theme' as *;

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Thin'), local('GreycliffCF-Thin'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-Thin.woff2) format('woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Extra Light'), local('GreycliffCF-ExtraLight'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-ExtraLight.woff2) format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Light'), local('GreycliffCF-Light'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-Light.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Medium'), local('GreycliffCF-Medium'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Demi Bold'), local('GreycliffCF-DemiBold'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-DemiBold.woff2) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Extra Bold'), local('GreycliffCF-ExtraBold'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-ExtraBold.woff2) format('woff2');
  font-weight: 800;
}

@font-face {
  font-family: 'Greycliff CF';
  font-display: swap;
  src: local('Greycliff CF Heavy'), local('GreycliffCF-Heavy'),
    url(/assets/fonts/GreycliffCF/GreycliffCF-Heavy.woff2) format('woff2');
  font-weight: 900;
}

@font-face {
  font-family: 'Comforter Brush';
  src: url('/assets/fonts/others/ComforterBrush-Regular.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Italianno';
  src: url('/assets/fonts/others/italianno.regular.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Edwardian Script ITC';
  src: url('/assets/fonts/others/edwardianscriptitc.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Freestyle Script';
  src: url('/assets/fonts/others/FREESCPT.TTF') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Brush Script MT';
  src: url('/assets/fonts/others/SimpleBrushScript.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Lucida Handwriting';
  font-display: swap;
  src: local('Lucida Handwriting Thin'), local('LucidaHandwriting-Thin'),
    url(/assets/fonts/lucida-handwriting-std/LucidaHandwritingStdThin.TTF)
      format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Lucida Handwriting';
  font-display: swap;
  src: local('Lucida Handwriting Light'), local('LucidaHandwriting-Light'),
    url(/assets/fonts/lucida-handwriting-std/LucidaHandwritingStdLight.TTF)
      format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Lucida Handwriting';
  font-display: swap;
  src: local('Lucida Handwriting Bold'), local('LucidaHandwriting-Bold'),
    url(/assets/fonts/lucida-handwriting-std/LucidaHandwritingStdBold.TTF)
      format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Lucida Handwriting';
  font-display: swap;
  src: local('Lucida Handwriting Black'), local('LucidaHandwriting-Black'),
    url(/assets/fonts/lucida-handwriting-std/LucidaHandwritingStdBlack.TTF)
      format('truetype');
  font-weight: 900;
}

// .flwmn-display-1 {
//   font-size: 4.5rem;
//   color: var(--flwmn-black);
// }

// .flwmn-display-2 {
//   font-size: 3.75rem;
//   color: var(--flwmn-black);
// }

.flwmn-display-1 {
  font-size: 4rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 2.25rem;
  }
}

.flwmn-heading-1 {
  font-size: 3rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 2.125rem;
  }
}

.flwmn-heading-2 {
  font-size: 2.5rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 2.0625rem;
  }
}

.flwmn-heading-3 {
  font-size: 2.0625rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 1.75rem;
  }
}

.flwmn-heading-4 {
  font-size: 1.75rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 1.4375rem;
  }
}

.flwmn-heading-5 {
  font-size: 1.4375rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 1.1875rem;
  }
}

.flwmn-heading-6 {
  font-size: 1.1875rem;
  color: var(--flwmn-black);

  @include flwmn-media-query-max(flwmn-md) {
    font-size: 1rem;
  }
}

.flwmn-sub-heading {
  font-size: 1.25rem;
  color: var(--flwmn-black);
}

.flwmn-para-3 {
  font-size: 1.125rem;
  color: var(--flwmn-black);
}

.flwmn-para-2 {
  font-size: 1rem;
  color: var(--flwmn-black);
}

.flwmn-para-1 {
  font-size: 0.875rem;
  color: var(--flwmn-black);
}

.flwmn-caption {
  font-size: 0.75rem;
  color: var(--flwmn-black);
}

.flwmn-footer {
  font-size: 0.625rem;
  color: var(--flwmn-black);
}

/*** Font Weight ***/

.fw-100 {
  font-weight: 100;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

/*******************/

/*** Font Style ****/

.fs-i {
  font-style: italic;
}

/*******************/

/*** Line Height ****/

.lh-1 {
  line-height: 0.75;
}

.lh-2 {
  line-height: 1;
}

.lh-3 {
  line-height: 1.25;
}

.lh-4 {
  line-height: 1.5;
}

.lh-5 {
  line-height: 1.75;
}

.lh-6 {
  line-height: 2;
}

.lh-7 {
  line-height: 2.25;
}

.lh-8 {
  line-height: 2.5;
}

.lh-none {
  line-height: 1;
}

.lh-tight {
  line-height: 1.25;
}

.lh-snug {
  line-height: 1.375;
}

.lh-normal {
  line-height: 1.5;
}

.lh-relaxed {
  line-height: 1.625;
}

.lh-loose {
  line-height: 2;
}

/*******************/
