@use '../abstracts/theme' as *;

.mat-table {
  width: 100% !important;
  margin-top: 0.9375rem !important;

  td,
  th {
    white-space: nowrap !important;
    padding-right: 1.5625rem !important;

    // &:first-child {
    //   width: 5% !important;
    // }
  }

  td {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    color: var(--flwmn-black) !important;
  }

  td.mat-cell {
    border-bottom-color: var(--flwmn-neutral-100) !important;
  }

  th.mat-header-cell {
    border-bottom: none !important;
  }

  thead {
    background: var(--flwmn-neutral-50) !important;
  }

  tr.mat-header-row {
    height: 2.75rem !important;
  }

  .mat-sort-header-content {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    color: var(--flwmn-neutral) !important;
  }

  .mat-sort-header-arrow {
    color: var(--flwmn-primary-color) !important;
  }

  .action {
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 1.7rem;
    color: var(--flwmn-primary-color);
  }

  .status {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 400;
    border-radius: 0.3125rem;
    padding: 0.3rem 1rem;
    line-height: 2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .primary {
    background-color: var(--flwmn-primary-color-100);
    color: var(--flwmn-primary-color);
  }

  .warning {
    background-color: var(--flwmn-warning-50);
    color: var(--flwmn-warning);
  }

  .neutral {
    background-color: var(--flwmn-neutral-100);
    color: var(--flwmn-neutral);
  }

  .error {
    background-color: var(--flwmn-error-50);
    color: var(--flwmn-error);
  }

  .status-active,
  .status-pending,
  .status-inactive,
  .status-primary,
  .status-danger,
  .status-accent {
    font-size: 0.75rem;
    font-weight: 400;
    border-radius: 0.3125rem;
    padding: 0.125rem 0.625rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .status-active {
    background-color: var(--flwmn-success-50);
    color: var(--flwmn-success);
  }

  .status-pending {
    background-color: var(--flwmn-warning-50);
    color: var(--flwmn-warning);
  }

  .status-inactive {
    background-color: var(--flwmn-neutral-50);
    color: var(--flwmn-neutral);
  }

  .status-primary {
    background-color: var(--flwmn-primary-color-50);
    color: var(--flwmn-primary-color);
  }

  .status-danger {
    background-color: var(--flwmn-error-50);
    color: var(--flwmn-error);
  }

  .status-accent {
    background-color: var(--flwmn-accent-color-50);
    color: var(--flwmn-accent-color);
  }

  .table-cta-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    gap: 0.5rem;

    .cta-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 0.3125rem;
      // padding: 0.125rem 0.25rem;
      padding: 0.25rem;
      gap: 0.25rem;
      color: var(--flwmn-black);
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }

      img {
        -webkit-user-drag: none;
      }
    }
  }

  .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.custom-tooltip {
  background: var(--flwmn-black) !important;
  color: var(--flwmn-white) !important;
  font-size: 0.6875rem !important;
}

.selectable-row {
  cursor: pointer;
  -webkit-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;

  &:hover {
    -webkit-box-shadow: 0px 4px 24px rgba(60, 69, 115, 0.06);
    box-shadow: 0px 4px 24px rgba(60, 69, 115, 0.06);
  }
}

.flwmn-table-filter {
  padding: 0rem;
  // -webkit-box-shadow: 0px 11.61887264251709px 124.27999877929688px 0px #2548992b !important;
  // box-shadow: 0px 11.61887264251709px 124.27999877929688px 0px #2548992b !important;
  -webkit-box-shadow: 0px 2px 10px 0px #2222221a !important;
  box-shadow: 0px 2px 10px 0px #2222221a !important;
  border-radius: 0.3125rem !important;

  .flwmn-table-filter-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.25rem 1rem;

    .flwmn-table-filter-body-title {
      color: var(--flwmn-neutral-400);
    }

    .flwmn-table-filter-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 0.75rem;

      .mat-checkbox-layout .mat-checkbox-label {
        font-size: 0.75rem !important;
      }
    }

    .flwmn-table-filter-body-cta-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}

.click {
  cursor: pointer;
}

.flwmn-table {
  &.mat-table {
    width: 100% !important;
    margin-top: 0.9375rem !important;

    border-collapse: separate !important;
    border-spacing: 0 0.75rem !important;

    tr.mat-row {
      background-color: #fff !important;
      border-radius: 0.5rem !important;
      box-shadow: 0 0 2px 0 #2222221a;
    }

    td {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      color: var(--flwmn-black) !important;
      padding: 0.75rem 1rem !important;
    }

    td.mat-cell {
      border-block: 0.0625rem solid var(--flwmn-neutral-100);

      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem !important;
        border-left: 0.0625rem solid var(--flwmn-neutral-100);
      }

      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0 !important;
        border-right: 0.0625rem solid var(--flwmn-neutral-100);
      }
    }
  }
}
