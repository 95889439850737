.flwmn-progress-bar {
  $flwmn-success-50: #97eb8b;

  &.mat-progress-bar {
    height: 0.5rem !important;
    border-radius: 0.125rem;
  }

  & .mat-progress-bar-buffer {
    background-color: var(--flwmn-neutral-100);
  }

  &.progress-primary {
    & .mat-progress-bar-fill::after {
      background-color: var(--flwmn-primary-color) !important;
    }
  }

  &.progress-info {
    & .mat-progress-bar-fill::after {
      background-color: var(--flwmn-info) !important;
    }
  }

  &.progress-accent {
    & .mat-progress-bar-fill::after {
      background-color: var(--flwmn-warning) !important;
    }
  }

  &.progress-success {
    & .mat-progress-bar-fill::after {
      background-color: $flwmn-success-50 !important;
    }
  }

  &.progress-danger {
    & .mat-progress-bar-fill::after {
      background-color: var(--flwmn-error) !important;
    }
  }
}

.flwmn-progress-2 {
  &.mat-progress-bar {
    height: 1.5rem !important;
    border-radius: 0.125rem;
  }
}

.flwmn-progress-round {
  &.mat-progress-bar {
    border-radius: 0.5rem !important;
  }
}
