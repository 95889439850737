.flwmn-drive-scroll-bar {
  /* For WebKit browsers (e.g., Chrome, Safari) */
  overflow-y: overlay;
  overflow-x: hidden;
  scroll-behavior: smooth;
  // -ms-overflow-style: none;
  // scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5rem;
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.7rem;
    margin-block: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0.09375rem solid var(--flwmn-white);
    border-radius: 0.9rem;
  }
}
