@use '../abstracts/theme' as *;

.flwmn-ph-item {
  padding: 0rem;
  border: none;
}

.ph-item > * {
  justify-content: center;
}

.ph-row {
  align-items: center;
}
