@use '../abstracts/theme' as *;

.folder-panel-tooltip {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  background: var(--flwmn-primary-color) !important;
  padding: 0.375rem 1rem !important;
}

.control-tooltip {
  font-size: 0.75rem !important;
  background: #222222 !important;
}

.document-setup-tooltip {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: var(--flwmn-black) !important;
  padding: 1rem !important;
  background: var(--flwmn-white) !important;
  border-radius: 0.25rem !important;
  -webkit-box-shadow: 0px 2px 10px 0px #2222221a !important;
  box-shadow: 0px 2px 10px 0px #2222221a !important;
}
