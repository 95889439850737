@use '../abstracts/theme' as *;

/* Change the white to any color */

input:autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff00 inset !important;
  box-shadow: 0 0 0 30px #ffffff00 inset !important;
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

/* Change font-size and font family of input when autocomplete kicks in*/

input:autofill,
input:autofill::first-line,
input:-webkit-autofill::first-line,
textarea:autofill,
textarea:autofill::first-line,
textarea:-webkit-autofill::first-line {
  // font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: var(--flwmn-black) !important;
}

input::-webkit-input-placeholder {
  color: var(--flwmn-neutral-400);
}

input::-moz-placeholder {
  color: var(--flwmn-neutral-400);
}

input:-ms-input-placeholder {
  color: var(--flwmn-neutral-400);
}

input::-ms-input-placeholder {
  color: var(--flwmn-neutral-400);
}

input::placeholder {
  color: var(--flwmn-neutral-400);
}

textarea::-webkit-input-placeholder {
  color: var(--flwmn-neutral-400);
}

textarea::-moz-placeholder {
  color: var(--flwmn-neutral-400);
}

textarea:-ms-input-placeholder {
  color: var(--flwmn-neutral-400);
}

textarea::-ms-input-placeholder {
  color: var(--flwmn-neutral-400);
}

textarea::placeholder {
  color: var(--flwmn-neutral-400);
}

select::-webkit-input-placeholder {
  color: var(--flwmn-neutral-400);
}

select::-moz-placeholder {
  color: var(--flwmn-neutral-400);
}

select:-ms-input-placeholder {
  color: var(--flwmn-neutral-400);
}

select::-ms-input-placeholder {
  color: var(--flwmn-neutral-400);
}

select::placeholder {
  color: var(--flwmn-neutral-400);
}

// input[type='time']::-webkit-calendar-picker-indicator {
//   background: url(/assets/icons/clock.svg) no-repeat;
// }

/******************Make arrow on input type number to disappear******************/
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}
/********************************************************************************/

textarea {
  resize: none !important;
}

input,
textarea,
.mat-select-value,
textarea.mat-input-element,
.custom-textarea {
  -o-text-overflow: ellipsis !important;
  text-overflow: ellipsis !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: var(--flwmn-black) !important;
  vertical-align: middle !important;
}

input[type='color'] {
  border: 0.1rem solid var(--flwmn-neutral-100);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  overflow: hidden;

  &::-moz-color-swatch,
  &::-webkit-color-swatch-wrapper,
  &::-webkit-color-swatch {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

input[type='color']::-webkit-color-swatch {
  border: none;
}

input.mat-input-element {
  margin-top: 0em !important;
}

.mat-chip-list-wrapper input.mat-input-element,
.mat-chip-list-wrapper .mat-standard-chip {
  margin: 4px !important;
}

.mat-select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  min-width: 0.625rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0rem !important;
}

.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-start,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-end,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-gap {
  border-width: 0.0875rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-width: 0.09375rem !important;
}

/*If you want to change the base color of the input, 
 *the following styles are needed compensate for the other states
*/

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--flwmn-neutral-200) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--flwmn-black) !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--flwmn-primary-color) !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  color: var(--flwmn-error) !important;
}
/*******************************************************************/

.mat-option-text {
  font-weight: 400;
}

.mat-form-field-label-wrapper {
  top: 0rem !important;
  padding-top: 0rem !important;
}

.mat-option {
  // font-size: 0.875rem !important;
  font-size: 0.75rem !important;
}

// .mat-select-trigger {
//   height: 0rem !important;
// }

.input-label {
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
  font-weight: 400;
  text-align: start;
  color: var(--flwmn-black);

  &-fw600 {
    font-weight: 600;
  }

  span {
    font-size: 0.625rem;
    font-style: italic;
  }
}

.input-fluid {
  flex-grow: 1;

  .mat-form-field-infix {
    width: auto;
  }
}

.input-borderless {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: transparent !important;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: var(--flwmn-primary-color) !important;
  }

  .mat-form-field-appearance-outline:hover .mat-form-field-outline {
    color: var(--flwmn-neutral-200) !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.25rem 0 0.5rem !important;
  }
}

.input-no-error {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    margin-top: 0rem !important;
  }

  // custom max-width extension

  &.mw-sm {
    max-width: 28.7rem;
  }
}

.input-fit-content {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    &:not(:has(mat-chip-list, textarea)) {
      width: fit-content;
    }

    &:has(mat-chip-list) {
      width: fit-content;
    }
  }

  // .mat-select-trigger {
  //   gap: 0.125rem;
  // }
}

.input-style1-white-bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1.125rem 0 1.125rem;
    margin-top: 0em;
    background-color: var(--flwmn-white);
    border-radius: 0.3rem;
  }

  .mat-form-field-disabled.mat-form-field-appearance-outline {
    .mat-form-field-flex {
      background: var(--flwmn-neutral-50);
      border-radius: 0.25rem;

      input,
      .mat-select-value {
        color: var(--flwmn-neutral-400) !important;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0em;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0rem !important;
    margin-right: 0.5rem !important;

    img {
      -webkit-user-drag: none;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0rem !important;
    margin-left: 0.5rem !important;

    img {
      -webkit-user-drag: none;
    }

    .mat-button-wrapper:has(.calendar) {
      width: 1.125rem !important;
      height: 1.125rem !important;
    }
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-prefix
    .mat-icon-button,
  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button {
    width: inherit !important;
    height: inherit !important;
    line-height: inherit !important;
    border-radius: 0.25rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    border-top: none;

    &:has(input, mat-select) {
      &:not(:has(mat-chip-list, textarea)) {
        height: 2rem;
      }

      &:has(mat-chip-list) {
        min-height: 2rem;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
  }

  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    margin-top: 0.5rem !important;
  }
}

.input-style1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  &.input-padding-sm {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      padding: 0 0.5rem 0 0.5rem;
      margin-top: 0em;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1.125rem 0 1.125rem;
    margin-top: 0em;
  }

  .mat-form-field-disabled.mat-form-field-appearance-outline {
    .mat-form-field-flex {
      background: var(--flwmn-neutral-50);
      border-radius: 0.25rem;

      input,
      .mat-select-value {
        color: var(--flwmn-neutral-400) !important;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0em;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0rem !important;
    margin-right: 0.5rem !important;

    img {
      -webkit-user-drag: none;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0rem !important;
    margin-left: 0.5rem !important;

    img {
      -webkit-user-drag: none;
    }

    .mat-button-wrapper:has(.calendar) {
      width: 1.125rem !important;
      height: 1.125rem !important;
    }
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-prefix
    .mat-icon-button,
  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button {
    width: inherit !important;
    height: inherit !important;
    line-height: inherit !important;
    border-radius: 0.25rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    border-top: none;
    gap: 0.5rem;

    &:has(input, mat-select) {
      &:not(:has(mat-chip-list, textarea)) {
        height: 2rem;
      }

      &:has(mat-chip-list) {
        min-height: 2rem;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
  }

  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    margin-top: 0.5rem !important;
  }
}

.input-md {
  input,
  textarea,
  .mat-select-value,
  textarea.mat-input-element,
  .custom-textarea {
    font-size: 1rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    &:not(:has(mat-chip-list, textarea)) {
      height: 2.5rem !important;
    }

    &:has(mat-chip-list) {
      min-height: 2.5rem !important;
    }
  }
}

.input-lg {
  input,
  textarea,
  .mat-select-value,
  textarea.mat-input-element,
  .custom-textarea {
    font-size: 1.125rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    &:not(:has(mat-chip-list, textarea)) {
      height: 3rem !important;
    }

    &:has(mat-chip-list) {
      min-height: 3rem !important;
    }
  }
}

.input-style-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  :nth-child(1) {
    &.mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0 0 0 0 !important;
    }

    &.mat-form-field-disabled.mat-form-field-appearance-outline {
      .mat-form-field-flex {
        border-radius: 0.25rem 0rem 0rem 0.25rem !important;
      }
    }

    &.mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-right-width: 0.0625rem !important;
    }
  }

  :nth-child(2) {
    &.mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-radius: 0 0 0 0 !important;
    }

    &.mat-form-field-disabled.mat-form-field-appearance-outline {
      .mat-form-field-flex {
        border-radius: 0rem 0.25rem 0.25rem 0rem !important;
      }
    }

    &.mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-left-width: 0.0625rem !important;
    }
  }
}

.flwmn-input-group {
  display: flex;
  align-items: center;
  gap: 0;
  border-radius: 0.25rem;
  overflow: hidden;

  & > * + * {
    margin-left: -0.125rem;

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-radius: 0 !important;
      border-left: 0;
    }
  }

  & > *:first-child {
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0 !important;
      border-right: 0;
    }

    border-radius: 0.25rem 0 0 0.25rem;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  button {
    min-height: 2rem;
    max-height: 2rem;
    border-width: 0.09375rem;
    border-color: var(--flwmn-neutral-200);
  }
}

.input-style-rounded-edge {
  margin-bottom: 0 !important;

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    width: 100%;
    border-radius: 0.5rem !important;
    border: 0.0625rem solid var(--flwmn-black) !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    display: none !important;
  }

  .mat-form-field-outline-gap {
    display: none !important;
  }
}
