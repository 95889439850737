@use '../abstracts/colors' as *;

.flwmn-chip {
  font-size: 0.75rem !important;
  color: var(--flwmn-primary-color) !important;
  padding: 0.25rem !important;
  min-height: unset !important;
  height: unset !important;
  background: var(--flwmn-primary-color-50) !important;
  border-radius: 0.25rem !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  &-neutral {
    padding: 0.25rem 0.5rem !important;
    background: var(--flwmn-neutral-100) !important;
  }

  &-accent {
    padding: 0.25rem 0.5rem !important;
    background: var(--flwmn-accent-color-100) !important;
  }

  &-md {
    padding: 0.25rem 0.5rem;
    border-radius: 0.75rem;
    text-align: center;
    font-size: 0.75rem;
    background: var(--flwmn-accent-color-200);
    flex-shrink: 0;

    &.user {
      background-color: var(--flwmn-accent-color-200);
    }

    &.department {
      background-color: $flwmn-theme5-accent-100;
    }

    &.role {
      background-color: $flwmn-theme2-accent-100;
    }

    &.unit {
      background-color: var(--flwmn-accent-color-200);
    }

    &.division {
      background-color: $flwmn-theme7-accent-100;
    }

    &.job-function {
      background-color: $flwmn-theme3-accent-100;
    }

    &.user-group {
      background-color: #b7efc5;
    }
  }

  &:hover {
    opacity: 1 !important;
  }

  &.chip-md {
    background-color: var(--flwmn-primary-color-100) !important;
    color: var(--flwmn-primary-color) !important;
    padding: 0.25rem 1rem !important;
  }

  &.mat-chip.mat-standard-chip .mat-chip-remove {
    opacity: 1 !important;
  }
}

.flwmn-mat-chip {
  font-size: 0.75rem !important;
  color: var(--flwmn-primary-color) !important;
  padding: 0.25rem 0.5rem !important;
  min-height: unset !important;
  height: unset !important;
  background: var(--flwmn-primary-color-50) !important;
  border-radius: 0.25rem;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  mat-icon {
    color: var(--flwmn-error) !important;
  }

  &.mat-chip.mat-standard-chip .mat-chip-remove {
    opacity: 1 !important;
  }
}
