#drive-comment-dropdown {
  list-style-type: none;
  position: absolute;
  background: #ffffff;
  border: 1px solid #64748b;
  max-height: 15rem;
  width: 100%;
  max-width: 21rem;
  z-index: 10;
  border-radius: 0.5rem;
  overflow-y: auto;
  padding: 0.3rem 0;

  .list-item {
    margin: 0.08rem;
    transition: all 50ms ease-in;
  }

  .list-item:last-child {
    border-bottom: none;
  }

  .suggestion-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    cursor: pointer;

    .initials {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8a8686;
      color: #ffffff;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem;
      text-transform: uppercase;
    }

    span {
      font-weight: 600;
    }
  }

  .selected {
    border-radius: 0.4rem;
    background: #b8c0cc;
  }

  /* For WebKit browsers (e.g., Chrome, Safari) */
  overflow-y: overlay;
  overflow-x: hidden;
  scroll-behavior: smooth;
  // -ms-overflow-style: none;
  // scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5rem;
    scroll-behavior: auto;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.7rem;
    margin-block: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0.09375rem solid var(--flwmn-white);
    border-radius: 0.9rem;
  }
}
