@use '../abstracts/media-query' as *;
@use '../abstracts/theme' as *;

.flwmn-sheet {
  .close-icon-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;

    img {
      -webkit-user-drag: none;
    }
  }

  @include flwmn-media-query-max(flwmn-xsm) {
    width: 100% !important;
  }
}

.flwmn-sheet-contained {
  height: calc(100vh - 3.75rem) !important;
  margin-top: 3.75rem !important;
}
