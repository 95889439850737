@use 'sass:map';

$min-width-breakpoints: (
  flwmn-sm: 576px,
  flwmn-md: 768px,
  flwmn-lg: 992px,
  flwmn-xl: 1200px,
  flwmn-xxl: 1400px,
);

$max-width-breakpoints: (
  flwmn-xsm: 575.98px,
  flwmn-sm: 767.98px,
  flwmn-md: 991.98px,
  flwmn-lg: 1199.98px,
  flwmn-xl: 1399.98px,
);

@mixin flwmn-media-query-min($key) {
  $size: map.get($min-width-breakpoints, $key);

  @media only screen and (min-width: $size) {
    @content;
  }
}

@mixin flwmn-media-query-max($key) {
  $size: map.get($max-width-breakpoints, $key);

  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin flwmn-media-query-between($key1, $key2) {
  $max: map.get($max-width-breakpoints, $key1);
  $min: map.get($min-width-breakpoints, $key2);

  @media only screen and (max-width: $max) and (min-width: $min) {
    @content;
  }
}

@mixin flwmn-media-query-between-normal($key1, $key2) {
  @media only screen and (max-width: $key1) and (min-width: $key2) {
    @content;
  }
}
