@use '../abstracts/theme' as *;
@use '../abstracts/media-query' as *;

// .btn {
//   display: -webkit-inline-box;
//   display: -ms-inline-flexbox;
//   display: inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   font-size: 0.875rem;
//   font-weight: 500;
//   padding: 0.3125rem 0.75rem;
//   border-radius: 0.25rem;
//   -webkit-transition: all 0.25s ease-in-out;
//   -o-transition: all 0.25s ease-in-out;
//   transition: all 0.25s ease-in-out;

//   &:focus {
//     -webkit-box-shadow: none;
//     box-shadow: none;
//   }

//   &.disabled,
//   &:disabled {
//     background: var(--flwmn-neutral-200);
//     color: var(--flwmn-neutral-400);
//     opacity: 1;
//     cursor: not-allowed;
//   }
// }

// .flwmn-btn-md {
//   font-size: 1rem !important;
//   padding: 0.375rem 1rem !important;
// }

// .flwmn-btn-lg {
//   font-size: 1.125rem !important;
//   padding: 0.4375rem 1.25rem !important;
// }

// .flwmn-btn-xl {
//   font-size: 1.25rem !important;
//   padding: 0.75rem 1.5rem !important;
// }

// .flwmn-btn-full {
//   width: 100%;
// }

// .flwmn-btn-with-icon {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   gap: 0.5rem;
// }

// .flwmn-btn-primary {
//   background-color: var(--flwmn-primary-color);
//   color: var(--flwmn-white);

//   &:hover {
//     background-color: var(--flwmn-primary-color-400);
//     color: var(--flwmn-white);
//   }

//   &:focus {
//     background-color: var(--flwmn-primary-color-700);
//     color: var(--flwmn-white);
//   }
// }

// .flwmn-btn-primary-outline {
//   color: var(--flwmn-primary-color);
//   border: 0.0625rem solid var(--flwmn-primary-color);

//   &:hover {
//     color: var(--flwmn-primary-color);
//   }

//   &:focus {
//     color: var(--flwmn-primary-color);
//   }
// }

// .flwmn-btn-neutral {
//   color: var(--flwmn-neutral);
//   border: 0.0625rem solid var(--flwmn-neutral-100);
//   background-color: var(--flwmn-neutral-50);

//   &:hover,
//   &:focus {
//     background-color: var(--flwmn-neutral-50);
//     color: var(--flwmn-neutral);
//   }
// }

.flwmn-accordion {
  padding: 1.5rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 2px 10px 0px #2222221a;
  box-shadow: 0px 2px 10px 0px #2222221a;

  .header {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }
  margin-bottom: 5.25rem;
  @include flwmn-media-query-max(flwmn-sm) {
    padding: 1.875rem 0.625rem;
  }

  .accordion-header {
    border-top: 0.0625rem solid var(--flwmn-neutral-100);
  }

  .accordion-button {
    padding: 1rem 1.5rem;
    font-weight: 600;
    font-sizek: 1.25rem;
    line-height: 1.5rem;
    color: var(--flwmn-neutral-800);
    background-color: inherit !important;
    border: 0 !important;
    overflow-anchor: none !important;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    &:focus {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    &:not(.collapsed) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:not(.collapsed)::after {
      background-image: url(/assets/icons/minus.svg);
    }

    &::after {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto;
      content: '';
      background-image: url(/assets/icons/add.svg);
      background-repeat: no-repeat;
      background-size: 1.5rem;
    }
  }

  .accordion-item {
    border: none;
  }

  .accordion-body {
    border: none;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: var(--flwmn-neutral-700);
    padding: 0.5rem 1.5rem 1rem;
  }
}

.flwmn-accordion2 {
  margin-bottom: 5.25rem;

  .header {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }

  @include flwmn-media-query-max(flwmn-sm) {
    padding: 1.875rem 0.625rem;
  }

  .accordion-button {
    padding: 1rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--flwmn-neutral-800);
    background-color: inherit !important;
    overflow-anchor: none !important;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    &:focus {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    &:not(.collapsed) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:not(.collapsed)::after {
      background-image: url(/assets/icons/minus.svg);
    }

    &::after {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto;
      background-image: url(/assets/icons/add.svg);
      background-repeat: no-repeat;
      background-size: 1.5rem;
    }
  }

  .accordion-item {
    border: 0.0625em solid #a0abbb;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  .accordion-body {
    border: none;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: var(--flwmn-neutral-700);
    padding: 0 1.5rem 1rem;
  }
}

.flwmn-accordion-sidebar {
  .accordion-button {
    padding: 0.3125rem 1.25rem;
    height: 2rem;
    font-family: 'Greycliff CF';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: var(--flwmn-neutral-800);
    background-color: inherit !important;
    overflow-anchor: none !important;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    &:focus {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    &.active {
      background: var(--flwmn-primary-color-50) !important;
      border-left: 0.1875rem solid var(--flwmn-primary-color) !important;
      color: var(--flwmn-primary-color-400);
    }

    &:hover {
      background: var(--flwmn-primary-color-50) !important;
    }

    &:not(.collapsed) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:not(.collapsed)::after {
      background-image: url(/assets/icons/chevron-down-active.svg);
      -webkit-transition: 0.3s all ease-in-out;
      -o-transition: 0.3s all ease-in-out;
      transition: 0.3s all ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }

    &::after {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto;
      content: '';
      background-image: url(/assets/icons/chevron-down.svg);
      background-repeat: no-repeat;
      background-size: 1.5rem;
    }
  }

  .accordion-item {
    border: none;
    margin-bottom: 0.5rem;
  }

  .accordion-body {
    border: none;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: var(--flwmn-neutral-700);
    padding: 0.5rem 0 0;
  }
}
