@use '../abstracts/theme' as *;

.flwmn-notification-success,
.flwmn-notification-warning,
.flwmn-notification-neutral,
.flwmn-notification-error {
  border-radius: 0.25rem !important;
  padding: 0.75rem 1rem !important;
  min-height: 3rem !important;
  -webkit-box-shadow: 0px 10px 25px rgba(232, 232, 232, 0.5) !important;
  box-shadow: 0px 10px 25px rgba(232, 232, 232, 0.5) !important;
}

.flwmn-notification-success {
  color: var(--flwmn-success) !important;
  // color: var(--flwmn-black) !important;
  background: var(--flwmn-success-50) !important;
}

.flwmn-notification-warning {
  // color: var(--flwmn-warning) !important;
  color: var(--flwmn-black) !important;
  background: var(--flwmn-warning-50) !important;
}

.flwmn-notification-error {
  color: var(--flwmn-error) !important;
  // color: var(--flwmn-black) !important;
  background: var(--flwmn-error-50) !important;
}

.flwmn-notification-neutral {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--flwmn-black) !important;
  background: var(--flwmn-neutral-50) !important;
  box-shadow: none !important;
}
