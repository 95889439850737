.driver-popover.driverjs-theme {
  width: 22.5625rem;
  border-radius: 0.5rem;
  border: 0.125rem solid;
  padding: 1rem;
  background-color: var(--flwmn-white);
  // box-shadow: 0px 11.62px 124.28px 0px #2548992b;
  border-image-source: linear-gradient(123.43deg, #e413ff -4%, #4e33ff 104.41%);
  border-width: 0.0625rem;
  border-style: solid;
  z-index: 11000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;
    padding: 0.125rem;
    background: linear-gradient(123.43deg, #e413ff -4%, #4e33ff 104.41%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: -1;
  }
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 0.75rem;
  color: var(--flwmn-black);
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--flwmn-black);
  padding-bottom: 0.5;
  font-family: 'Greycliff CF', sans-serif;
}

.driver-popover.driverjs-theme .driver-popover-description {
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--flwmn-black);
  font-family: 'Greycliff CF', sans-serif;
}

.driver-popover.driverjs-theme .driver-popover-progress-text {
  font-weight: 500;
  font-size: 1rem;
  color: var(--flwmn-black);
}

.driver-popover.driverjs-theme button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.3125rem 0.75rem;
  border-radius: 0.25rem;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  gap: 0.25rem;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    background: var(--flwmn-neutral-200);
    color: var(--flwmn-neutral-400);
    border-color: var(--flwmn-neutral-200);
    opacity: 1;
    cursor: not-allowed;

    svg {
      path {
        stroke: var(--flwmn-neutral-400);
      }
    }
  }
}

.driver-popover.driverjs-theme button:hover {
  background-color: unset;
  color: unset;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button:nth-child(1) {
    color: var(--flwmn-black);
    border: 0.0625rem solid var(--flwmn-neutral-100);
    box-shadow: 2px 4px 10px 0px #00143d0d;
    border-radius: 0.3125rem;
    font-family: 'Greycliff CF', sans-serif;

    &:hover {
      color: var(--flwmn-black);
    }

    &:focus {
      color: var(--flwmn-black);
    }
  }

  button:nth-child(2) {
    background-color: var(--flwmn-primary-color);
    color: var(--flwmn-white);
    font-family: 'Greycliff CF', sans-serif;

    &:hover {
      background-color: var(--flwmn-primary-color-400);
      color: var(--flwmn-white);
    }

    &:focus {
      background-color: var(--flwmn-primary-color-700);
      color: var(--flwmn-white);
    }
  }
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  background-color: var(--flwmn-primary-color);
  color: var(--flwmn-white);
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  background-color: var(--flwmn-primary-color-400);
  color: var(--flwmn-white);
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: var(--flwmn-white);
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: var(--flwmn-white);
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: var(--flwmn-white);
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: var(--flwmn-white);
}

.driver-popover-footer button {
  text-shadow: unset !important;
}

.driver-popover-btn-disabled {
  display: none !important;
}
