/******* Default Palette *******/
$mat-flwmn-default-theme-primary-map: (
  50: #eae7ff,
  100: #cac2ff,
  200: #a799ff,
  300: #8370ff,
  400: #6952ff,
  500: #4e33ff,
  600: #472eff,
  700: #3d27ff,
  800: #3520ff,
  900: #2514ff,
  A100: #ffffff,
  A200: #fbfbff,
  A400: #cbc8ff,
  A700: #b3aeff,
  contrast: (
    50: #040101,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-default-theme-accent-map: (
  50: #ffe7ef,
  100: #ffc2d7,
  200: #ff99bd,
  300: #fe70a2,
  400: #fe528e,
  500: #fe337a,
  600: #fe2e72,
  700: #fe2767,
  800: #fe205d,
  900: #fd144a,
  A100: #ffffff,
  A200: #fff9fa,
  A400: #ffc6d1,
  A700: #ffadbd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-default-theme-warn-map: (
  50: #fde0e4,
  100: #fab3bc,
  200: #f78090,
  300: #f34d63,
  400: #f12641,
  500: #ee0020,
  600: #ec001c,
  700: #e90018,
  800: #e70013,
  900: #e2000b,
  A100: #ffffff,
  A200: #ffd6d7,
  A400: #ffa3a5,
  A700: #ff8a8c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
/*******************************/

/******* Webapp Palette *******/
$mat-flwmn-theme1-primary-map: (
  50: #eae7ff,
  100: #cac2ff,
  200: #a799ff,
  300: #8370ff,
  400: #6952ff,
  500: #4e33ff,
  600: #472eff,
  700: #3d27ff,
  800: #3520ff,
  900: #2514ff,
  A100: #ffffff,
  A200: #fbfbff,
  A400: #cbc8ff,
  A700: #b3aeff,
  contrast: (
    50: #040101,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme1-accent-map: (
  50: #ffe7ef,
  100: #ffc2d7,
  200: #ff99bd,
  300: #fe70a2,
  400: #fe528e,
  500: #fe337a,
  600: #fe2e72,
  700: #fe2767,
  800: #fe205d,
  900: #fd144a,
  A100: #ffffff,
  A200: #fff9fa,
  A400: #ffc6d1,
  A700: #ffadbd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme2-primary-map: (
  50: #fff0e0,
  100: #fedab3,
  200: #fdc280,
  300: #fcaa4d,
  400: #fc9726,
  500: #fb8500,
  600: #fa7d00,
  700: #fa7200,
  800: #f96800,
  900: #f85500,
  A100: #ffffff,
  A200: #fff1eb,
  A400: #ffccb8,
  A700: #ffba9f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme2-accent-map: (
  50: #e0efff,
  100: #b3d6fe,
  200: #80bbfd,
  300: #4d9ffc,
  400: #268bfc,
  500: #0076fb,
  600: #006efa,
  700: #0063fa,
  800: #0059f9,
  900: #0046f8,
  A100: #ffffff,
  A200: #ebf0ff,
  A400: #b8c8ff,
  A700: #9fb4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme3-primary-map: (
  50: #fae1e5,
  100: #f4b4bf,
  200: #ec8294,
  300: #e44f69,
  400: #df2a49,
  500: #d90429,
  600: #d50324,
  700: #cf031f,
  800: #ca0219,
  900: #c0010f,
  A100: #ffe9ea,
  A200: #ffb6b8,
  A400: #ff8387,
  A700: #ff696e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme3-accent-map: (
  50: #e1faf6,
  100: #b4f4e9,
  200: #82ecda,
  300: #4fe4cb,
  400: #2adfbf,
  500: #04d9b4,
  600: #03d5ad,
  700: #03cfa4,
  800: #02ca9c,
  900: #01c08c,
  A100: #e9fff8,
  A200: #b6ffe8,
  A400: #83ffd9,
  A700: #69ffd1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme4-primary-map: (
  50: #f4f0e4,
  100: #e4d8bb,
  200: #d2bf8d,
  300: #bfa55f,
  400: #b2913d,
  500: #a47e1b,
  600: #9c7618,
  700: #926b14,
  800: #896110,
  900: #784e08,
  A100: #ffdaa7,
  A200: #ffc574,
  A400: #ffb041,
  A700: #ffa528,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme4-accent-map: (
  50: #e4f4f0,
  100: #bbe4d8,
  200: #8dd2bf,
  300: #5fbfa5,
  400: #3db291,
  500: #1ba47e,
  600: #189c76,
  700: #14926b,
  800: #108961,
  900: #08784e,
  A100: #a7ffda,
  A200: #74ffc5,
  A400: #41ffb0,
  A700: #28ffa5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme5-primary-map: (
  50: #e5f4e9,
  100: #bee3c7,
  200: #92d1a2,
  300: #66be7c,
  400: #46b060,
  500: #25a244,
  600: #219a3e,
  700: #1b9035,
  800: #16862d,
  900: #0d751f,
  A100: #a7ffb3,
  A200: #74ff87,
  A400: #41ff5b,
  A700: #28ff45,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme5-accent-map: (
  50: #faece7,
  100: #f2d0c2,
  200: #e9b09a,
  300: #e09072,
  400: #da7953,
  500: #d36135,
  600: #ce5930,
  700: #c84f28,
  800: #c24522,
  900: #b73316,
  A100: #ffedea,
  A200: #ffc2b7,
  A400: #ff9784,
  A700: #ff816a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme6-primary-map: (
  50: #e5ecfe,
  100: #bfd1fd,
  200: #95b2fb,
  300: #6a93f9,
  400: #4a7bf8,
  500: #2a64f7,
  600: #255cf6,
  700: #1f52f5,
  800: #1948f3,
  900: #0f36f1,
  A100: #ffffff,
  A200: #eaedff,
  A400: #b7c1ff,
  A700: #9dabff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme6-accent-map: (
  50: #e8faf8,
  100: #c6f4ef,
  200: #a1ece4,
  300: #7be4d9,
  400: #5edfd0,
  500: #42d9c8,
  600: #3cd5c2,
  700: #33cfbb,
  800: #2bcab4,
  900: #1dc0a7,
  A100: #f7fffe,
  A200: #c4fff5,
  A400: #91ffed,
  A700: #78ffe8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-theme7-primary-map: (
  50: #efe6f7,
  100: #d7c0ec,
  200: #bd96df,
  300: #a36bd2,
  400: #8f4cc9,
  500: #7b2cbf,
  600: #7327b9,
  700: #6821b1,
  800: #5e1ba9,
  900: #4b109b,
  A100: #e0ccff,
  A200: #c099ff,
  A400: #a166ff,
  A700: #914dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$mat-flwmn-theme7-accent-map: (
  50: #eaf9fd,
  100: #c9effb,
  200: #a6e4f8,
  300: #82d9f5,
  400: #67d1f2,
  500: #4cc9f0,
  600: #45c3ee,
  700: #3cbcec,
  800: #33b5e9,
  900: #24a9e5,
  A100: #ffffff,
  A200: #ebf9ff,
  A400: #b8e8ff,
  A700: #9fdfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-flwmn-warn-map: (
  50: #fde0e4,
  100: #fab3bc,
  200: #f78090,
  300: #f34d63,
  400: #f12641,
  500: #ee0020,
  600: #ec001c,
  700: #e90018,
  800: #e70013,
  900: #e2000b,
  A100: #ffffff,
  A200: #ffd6d7,
  A400: #ffa3a5,
  A700: #ff8a8c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
/******************************/

/***********Default************/
$flwmn-default-theme-primary: #4e33ff;
$flwmn-default-theme-primary-50: #f6f5ff;
$flwmn-default-theme-primary-100: #dcd6ff;
$flwmn-default-theme-primary-200: #b8adff;
$flwmn-default-theme-primary-300: #9585ff;
$flwmn-default-theme-primary-400: #715cff;
$flwmn-default-theme-primary-600: #2d0dff;
$flwmn-default-theme-primary-700: #1900bf;
$flwmn-default-theme-primary-800: #0f0073;
$flwmn-default-theme-primary-900: #050026;

$flwmn-default-theme-primary-transparent: transparentize(
  $flwmn-default-theme-primary,
  0.95
);

$flwmn-default-theme-accent: #fe337a;
$flwmn-default-theme-accent-50: #fff5f8;
$flwmn-default-theme-accent-100: #ffe0eb;
$flwmn-default-theme-accent-200: #ffc2d7;
$flwmn-default-theme-accent-300: #ff99bd;
$flwmn-default-theme-accent-400: #fe669b;
$flwmn-default-theme-accent-600: #fe0d61;
$flwmn-default-theme-accent-700: #be0143;
$flwmn-default-theme-accent-800: #720128;
$flwmn-default-theme-accent-900: #26000d;

$flwmn-default-theme-accent-transparent: transparentize(
  $flwmn-default-theme-accent,
  0.95
);

$flwmn-default-theme-linear-gradient: linear-gradient(
  270deg,
  $flwmn-default-theme-primary 0%,
  $flwmn-default-theme-primary-400 107.89%
);

$flwmn-default-theme-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-default-theme-primary 104.41%
);
/******************************/

/***********Theme 1************/
$flwmn-theme1-primary: #4e33ff;
$flwmn-theme1-primary-50: #f6f5ff;
$flwmn-theme1-primary-100: #dcd6ff;
$flwmn-theme1-primary-200: #b8adff;
$flwmn-theme1-primary-300: #9585ff;
$flwmn-theme1-primary-400: #715cff;
$flwmn-theme1-primary-600: #2d0dff;
$flwmn-theme1-primary-700: #1900bf;
$flwmn-theme1-primary-800: #0f0073;
$flwmn-theme1-primary-900: #050026;

$flwmn-theme1-primary-transparent: transparentize($flwmn-theme1-primary, 0.95);

$flwmn-theme1-accent: #fe337a;
$flwmn-theme1-accent-50: #fff5f8;
$flwmn-theme1-accent-100: #ffe0eb;
$flwmn-theme1-accent-200: #ffc2d7;
$flwmn-theme1-accent-300: #ff99bd;
$flwmn-theme1-accent-400: #fe669b;
$flwmn-theme1-accent-600: #fe0d61;
$flwmn-theme1-accent-700: #be0143;
$flwmn-theme1-accent-800: #720128;
$flwmn-theme1-accent-900: #26000d;

$flwmn-theme1-accent-transparent: transparentize($flwmn-theme1-accent, 0.95);

$flwmn-theme1-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme1-primary 0%,
  $flwmn-theme1-primary-400 107.89%
);

$flwmn-theme1-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme1-primary 104.41%
);
/******************************/

/***********Theme 2************/
$flwmn-theme2-primary: #fb8500;
$flwmn-theme2-primary-50: #fff9f2;
$flwmn-theme2-primary-100: #ffe7cb;
$flwmn-theme2-primary-200: #ffce97;
$flwmn-theme2-primary-300: #ffb664;
$flwmn-theme2-primary-400: #ff9e30;
$flwmn-theme2-primary-600: #dc7400;
$flwmn-theme2-primary-700: #9d5300;
$flwmn-theme2-primary-800: #5e3200;
$flwmn-theme2-primary-900: #1f1100;

$flwmn-theme2-primary-transparent: transparentize($flwmn-theme2-primary, 0.95);

$flwmn-theme2-accent: #0076fb;
$flwmn-theme2-accent-50: #f1f7ff;
$flwmn-theme2-accent-100: #c5e1ff;
$flwmn-theme2-accent-200: #8cc2ff;
$flwmn-theme2-accent-300: #52a4ff;
$flwmn-theme2-accent-400: #1985ff;
$flwmn-theme2-accent-600: #0067dc;
$flwmn-theme2-accent-700: #004a9d;
$flwmn-theme2-accent-800: #002c5e;
$flwmn-theme2-accent-900: #000f1f;

$flwmn-theme2-accent-transparent: transparentize($flwmn-theme2-accent, 0.95);

$flwmn-theme2-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme2-primary 0%,
  $flwmn-theme2-primary-400 107.89%
);

$flwmn-theme2-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme2-primary 104.41%
);
/******************************/

/***********Theme 3************/
$flwmn-theme3-primary: #d90429;
$flwmn-theme3-primary-50: #fff1f3;
$flwmn-theme3-primary-100: #fec6d0;
$flwmn-theme3-primary-200: #fd8da1;
$flwmn-theme3-primary-300: #fc5572;
$flwmn-theme3-primary-400: #fb1c43;
$flwmn-theme3-primary-600: #be0424;
$flwmn-theme3-primary-700: #88031a;
$flwmn-theme3-primary-800: #51020f;
$flwmn-theme3-primary-900: #1b0105;

$flwmn-theme3-primary-transparent: transparentize($flwmn-theme3-primary, 0.95);

$flwmn-theme3-accent: #04d9b4;
$flwmn-theme3-accent-50: #effffc;
$flwmn-theme3-accent-100: #c0fef3;
$flwmn-theme3-accent-200: #81fde7;
$flwmn-theme3-accent-300: #42fcdb;
$flwmn-theme3-accent-400: #05f9ce;
$flwmn-theme3-accent-600: #04be9d;
$flwmn-theme3-accent-700: #038871;
$flwmn-theme3-accent-800: #025143;
$flwmn-theme3-accent-900: #011b17;

$flwmn-theme3-accent-transparent: transparentize($flwmn-theme3-accent, 0.95);

$flwmn-theme3-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme3-primary 0%,
  $flwmn-theme3-primary-400 107.89%
);

$flwmn-theme3-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme3-primary 104.41%
);
/******************************/

/***********Theme 4************/
$flwmn-theme4-primary: #a47e1b;
$flwmn-theme4-primary-50: #fdfaf1;
$flwmn-theme4-primary-100: #f6e9c8;
$flwmn-theme4-primary-200: #edd491;
$flwmn-theme4-primary-300: #e2b84d;
$flwmn-theme4-primary-400: #bf9320;
$flwmn-theme4-primary-600: #906e18;
$flwmn-theme4-primary-700: #674f11;
$flwmn-theme4-primary-800: #3e2f0a;
$flwmn-theme4-primary-900: #151003;

$flwmn-theme4-primary-transparent: transparentize($flwmn-theme4-primary, 0.95);

$flwmn-theme4-accent: #1ba47e;
$flwmn-theme4-accent-50: #f0fcf9;
$flwmn-theme4-accent-100: #c2f5e7;
$flwmn-theme4-accent-200: #85ebcf;
$flwmn-theme4-accent-300: #48e1b7;
$flwmn-theme4-accent-400: #20c295;
$flwmn-theme4-accent-600: #18906e;
$flwmn-theme4-accent-700: #11674f;
$flwmn-theme4-accent-800: #0a3e2f;
$flwmn-theme4-accent-900: #031510;

$flwmn-theme4-accent-transparent: transparentize($flwmn-theme4-accent, 0.95);

$flwmn-theme4-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme4-primary 0%,
  $flwmn-theme4-primary-400 107.89%
);

$flwmn-theme4-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme4-primary 104.41%
);
/******************************/

/***********Theme 5************/
$flwmn-theme5-primary: #25a244;
$flwmn-theme5-primary-50: #f2fff5;
$flwmn-theme5-primary-100: #92e6a7;
$flwmn-theme5-primary-200: #6ede8a;
$flwmn-theme5-primary-300: #4ad66d;
$flwmn-theme5-primary-400: #2dc653;
$flwmn-theme5-primary-600: #208b3a;
$flwmn-theme5-primary-700: #1a7431;
$flwmn-theme5-primary-800: #155d27;
$flwmn-theme5-primary-900: #10451d;

$flwmn-theme5-primary-transparent: transparentize($flwmn-theme5-primary, 0.95);

$flwmn-theme5-accent: #d36135;
$flwmn-theme5-accent-50: #fdf6f4;
$flwmn-theme5-accent-100: #f5dcd2;
$flwmn-theme5-accent-200: #ebb9a5;
$flwmn-theme5-accent-300: #e29678;
$flwmn-theme5-accent-400: #d8734b;
$flwmn-theme5-accent-600: #be5329;
$flwmn-theme5-accent-700: #873b1e;
$flwmn-theme5-accent-800: #512312;
$flwmn-theme5-accent-900: #1b0c06;

$flwmn-theme5-accent-transparent: transparentize($flwmn-theme5-accent, 0.95);

$flwmn-theme5-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme5-primary 0%,
  $flwmn-theme5-primary-400 107.89%
);

$flwmn-theme5-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme5-primary 104.41%
);
/******************************/

/***********Theme 6************/
$flwmn-theme6-primary: #2a64f7;
$flwmn-theme6-primary-50: #f4f7ff;
$flwmn-theme6-primary-100: #d4e0fd;
$flwmn-theme6-primary-200: #aac1fc;
$flwmn-theme6-primary-300: #7fa2fa;
$flwmn-theme6-primary-400: #5583f9;
$flwmn-theme6-primary-600: #094cf4;
$flwmn-theme6-primary-700: #0736ae;
$flwmn-theme6-primary-800: #042068;
$flwmn-theme6-primary-900: #010b23;

$flwmn-theme6-primary-transparent: transparentize($flwmn-theme6-primary, 0.95);

$flwmn-theme6-accent: #42d9c8;
$flwmn-theme6-accent-50: #f4fdfc;
$flwmn-theme6-accent-100: #d5f7f3;
$flwmn-theme6-accent-200: #abeee7;
$flwmn-theme6-accent-300: #81e6da;
$flwmn-theme6-accent-400: #57ddce;
$flwmn-theme6-accent-600: #29cebc;
$flwmn-theme6-accent-700: #1e9386;
$flwmn-theme6-accent-800: #125850;
$flwmn-theme6-accent-900: #061d1b;

$flwmn-theme6-accent-transparent: transparentize($flwmn-theme6-accent, 0.95);

$flwmn-theme6-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme6-primary 0%,
  $flwmn-theme6-primary-400 107.89%
);

$flwmn-theme6-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme6-primary 104.41%
);
/******************************/

/***********Theme 7************/
$flwmn-theme7-primary: #7b2cbf;
$flwmn-theme7-primary-50: #f8f3fc;
$flwmn-theme7-primary-100: #e2cdf4;
$flwmn-theme7-primary-200: #c59ce8;
$flwmn-theme7-primary-300: #a86add;
$flwmn-theme7-primary-400: #8a38d1;
$flwmn-theme7-primary-600: #6c27a7;
$flwmn-theme7-primary-700: #4d1c77;
$flwmn-theme7-primary-800: #2e1048;
$flwmn-theme7-primary-900: #0f0618;

$flwmn-theme7-primary-transparent: transparentize($flwmn-theme7-primary, 0.95);

$flwmn-theme7-accent: #4cc9f0;
$flwmn-theme7-accent-50: #f5fcfe;
$flwmn-theme7-accent-100: #d7f3fc;
$flwmn-theme7-accent-200: #afe7f8;
$flwmn-theme7-accent-300: #88dbf5;
$flwmn-theme7-accent-400: #60cff2;
$flwmn-theme7-accent-600: #28beed;
$flwmn-theme7-accent-700: #0f8fb6;
$flwmn-theme7-accent-800: #09566d;
$flwmn-theme7-accent-900: #031d24;

$flwmn-theme7-accent-transparent: transparentize($flwmn-theme7-accent, 0.95);

$flwmn-theme7-linear-gradient: linear-gradient(
  270deg,
  $flwmn-theme7-primary 0%,
  $flwmn-theme7-primary-400 107.89%
);

$flwmn-theme7-linear-gradient2: linear-gradient(
  123.43deg,
  #e413ff -4%,
  $flwmn-theme7-primary 104.41%
);
/******************************/

$flwmn-success: #10b981;
$flwmn-success-50: #ecfdf5;

$flwmn-warning: #f59e0b;
$flwmn-warning-50: #fff1d6;
$flwmn-warning-100: #8f5e00;

$flwmn-error: #ef4444;
$flwmn-error-50: #fef2f2;

$flwmn-info: #65b3fb;
$flwmn-info-50: #65b3fb;

$flwmn-white: #ffffff;
$flwmn-black: #222222;

$flwmn-neutral: #64748b;
$flwmn-neutral-50: #f7f8f9;
$flwmn-neutral-100: #e7eaee;
$flwmn-neutral-200: #d0d5dd;
$flwmn-neutral-300: #b8c0cc;
$flwmn-neutral-400: #a0abbb;
$flwmn-neutral-600: #4b5768;
$flwmn-neutral-700: #323a46;
$flwmn-neutral-800: #191d23;
$flwmn-neutral-900: #0d0f11;

$themed-colors: (
  --flwmn-default-theme: (
    --flwmn-primary-color: (
      default: $flwmn-default-theme-primary,
      50: $flwmn-default-theme-primary-50,
      100: $flwmn-default-theme-primary-100,
      200: $flwmn-default-theme-primary-200,
      300: $flwmn-default-theme-primary-300,
      400: $flwmn-default-theme-primary-400,
      600: $flwmn-default-theme-primary-600,
      700: $flwmn-default-theme-primary-700,
      800: $flwmn-default-theme-primary-800,
      900: $flwmn-default-theme-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-default-theme-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-default-theme-accent,
      50: $flwmn-default-theme-accent-50,
      100: $flwmn-default-theme-accent-100,
      200: $flwmn-default-theme-accent-200,
      300: $flwmn-default-theme-accent-300,
      400: $flwmn-default-theme-accent-400,
      600: $flwmn-default-theme-accent-600,
      700: $flwmn-default-theme-accent-700,
      800: $flwmn-default-theme-accent-800,
      900: $flwmn-default-theme-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-default-theme-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-default-theme-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-default-theme-linear-gradient2,
    ),
  ),
  --theme1: (
    --flwmn-primary-color: (
      default: $flwmn-theme1-primary,
      50: $flwmn-theme1-primary-50,
      100: $flwmn-theme1-primary-100,
      200: $flwmn-theme1-primary-200,
      300: $flwmn-theme1-primary-300,
      400: $flwmn-theme1-primary-400,
      600: $flwmn-theme1-primary-600,
      700: $flwmn-theme1-primary-700,
      800: $flwmn-theme1-primary-800,
      900: $flwmn-theme1-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme1-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme1-accent,
      50: $flwmn-theme1-accent-50,
      100: $flwmn-theme1-accent-100,
      200: $flwmn-theme1-accent-200,
      300: $flwmn-theme1-accent-300,
      400: $flwmn-theme1-accent-400,
      600: $flwmn-theme1-accent-600,
      700: $flwmn-theme1-accent-700,
      800: $flwmn-theme1-accent-800,
      900: $flwmn-theme1-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme1-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme1-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme1-linear-gradient2,
    ),
  ),
  --theme2: (
    --flwmn-primary-color: (
      default: $flwmn-theme2-primary,
      50: $flwmn-theme2-primary-50,
      100: $flwmn-theme2-primary-100,
      200: $flwmn-theme2-primary-200,
      300: $flwmn-theme2-primary-300,
      400: $flwmn-theme2-primary-400,
      600: $flwmn-theme2-primary-600,
      700: $flwmn-theme2-primary-700,
      800: $flwmn-theme2-primary-800,
      900: $flwmn-theme2-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme2-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme2-accent,
      50: $flwmn-theme2-accent-50,
      100: $flwmn-theme2-accent-100,
      200: $flwmn-theme2-accent-200,
      300: $flwmn-theme2-accent-300,
      400: $flwmn-theme2-accent-400,
      600: $flwmn-theme2-accent-600,
      700: $flwmn-theme2-accent-700,
      800: $flwmn-theme2-accent-800,
      900: $flwmn-theme2-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme2-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme2-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme2-linear-gradient2,
    ),
  ),
  --theme3: (
    --flwmn-primary-color: (
      default: $flwmn-theme3-primary,
      50: $flwmn-theme3-primary-50,
      100: $flwmn-theme3-primary-100,
      200: $flwmn-theme3-primary-200,
      300: $flwmn-theme3-primary-300,
      400: $flwmn-theme3-primary-400,
      600: $flwmn-theme3-primary-600,
      700: $flwmn-theme3-primary-700,
      800: $flwmn-theme3-primary-800,
      900: $flwmn-theme3-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme3-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme3-accent,
      50: $flwmn-theme3-accent-50,
      100: $flwmn-theme3-accent-100,
      200: $flwmn-theme3-accent-200,
      300: $flwmn-theme3-accent-300,
      400: $flwmn-theme3-accent-400,
      600: $flwmn-theme3-accent-600,
      700: $flwmn-theme3-accent-700,
      800: $flwmn-theme3-accent-800,
      900: $flwmn-theme3-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme3-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme3-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme3-linear-gradient2,
    ),
  ),
  --theme4: (
    --flwmn-primary-color: (
      default: $flwmn-theme4-primary,
      50: $flwmn-theme4-primary-50,
      100: $flwmn-theme4-primary-100,
      200: $flwmn-theme4-primary-200,
      300: $flwmn-theme4-primary-300,
      400: $flwmn-theme4-primary-400,
      600: $flwmn-theme4-primary-600,
      700: $flwmn-theme4-primary-700,
      800: $flwmn-theme4-primary-800,
      900: $flwmn-theme4-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme4-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme4-accent,
      50: $flwmn-theme4-accent-50,
      100: $flwmn-theme4-accent-100,
      200: $flwmn-theme4-accent-200,
      300: $flwmn-theme4-accent-300,
      400: $flwmn-theme4-accent-400,
      600: $flwmn-theme4-accent-600,
      700: $flwmn-theme4-accent-700,
      800: $flwmn-theme4-accent-800,
      900: $flwmn-theme4-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme4-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme4-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme4-linear-gradient2,
    ),
  ),
  --theme5: (
    --flwmn-primary-color: (
      default: $flwmn-theme5-primary,
      50: $flwmn-theme5-primary-50,
      100: $flwmn-theme5-primary-100,
      200: $flwmn-theme5-primary-200,
      300: $flwmn-theme5-primary-300,
      400: $flwmn-theme5-primary-400,
      600: $flwmn-theme5-primary-600,
      700: $flwmn-theme5-primary-700,
      800: $flwmn-theme5-primary-800,
      900: $flwmn-theme5-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme5-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme5-accent,
      50: $flwmn-theme5-accent-50,
      100: $flwmn-theme5-accent-100,
      200: $flwmn-theme5-accent-200,
      300: $flwmn-theme5-accent-300,
      400: $flwmn-theme5-accent-400,
      600: $flwmn-theme5-accent-600,
      700: $flwmn-theme5-accent-700,
      800: $flwmn-theme5-accent-800,
      900: $flwmn-theme5-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme5-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme5-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme5-linear-gradient2,
    ),
  ),
  --theme6: (
    --flwmn-primary-color: (
      default: $flwmn-theme6-primary,
      50: $flwmn-theme6-primary-50,
      100: $flwmn-theme6-primary-100,
      200: $flwmn-theme6-primary-200,
      300: $flwmn-theme6-primary-300,
      400: $flwmn-theme6-primary-400,
      600: $flwmn-theme6-primary-600,
      700: $flwmn-theme6-primary-700,
      800: $flwmn-theme6-primary-800,
      900: $flwmn-theme6-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme6-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme6-accent,
      50: $flwmn-theme6-accent-50,
      100: $flwmn-theme6-accent-100,
      200: $flwmn-theme6-accent-200,
      300: $flwmn-theme6-accent-300,
      400: $flwmn-theme6-accent-400,
      600: $flwmn-theme6-accent-600,
      700: $flwmn-theme6-accent-700,
      800: $flwmn-theme6-accent-800,
      900: $flwmn-theme6-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme6-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme6-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme6-linear-gradient2,
    ),
  ),
  --theme7: (
    --flwmn-primary-color: (
      default: $flwmn-theme7-primary,
      50: $flwmn-theme7-primary-50,
      100: $flwmn-theme7-primary-100,
      200: $flwmn-theme7-primary-200,
      300: $flwmn-theme7-primary-300,
      400: $flwmn-theme7-primary-400,
      600: $flwmn-theme7-primary-600,
      700: $flwmn-theme7-primary-700,
      800: $flwmn-theme7-primary-800,
      900: $flwmn-theme7-primary-900,
    ),
    --flwmn-primary-color-transparent: (
      default: $flwmn-theme7-primary-transparent,
    ),
    --flwmn-accent-color: (
      default: $flwmn-theme7-accent,
      50: $flwmn-theme7-accent-50,
      100: $flwmn-theme7-accent-100,
      200: $flwmn-theme7-accent-200,
      300: $flwmn-theme7-accent-300,
      400: $flwmn-theme7-accent-400,
      600: $flwmn-theme7-accent-600,
      700: $flwmn-theme7-accent-700,
      800: $flwmn-theme7-accent-800,
      900: $flwmn-theme7-accent-900,
    ),
    --flwmn-accent-color-transparent: (
      default: $flwmn-theme7-accent-transparent,
    ),
    --flwmn-linear-gradient: (
      default: $flwmn-theme7-linear-gradient,
    ),
    --flwmn-linear-gradient2: (
      default: $flwmn-theme7-linear-gradient2,
    ),
  ),
);

$general-colors: (
  --flwmn-white: (
    default: $flwmn-white,
  ),
  --flwmn-black: (
    default: $flwmn-black,
  ),
  --flwmn-neutral: (
    default: $flwmn-neutral,
    50: $flwmn-neutral-50,
    100: $flwmn-neutral-100,
    200: $flwmn-neutral-200,
    300: $flwmn-neutral-300,
    400: $flwmn-neutral-400,
    600: $flwmn-neutral-600,
    700: $flwmn-neutral-700,
    800: $flwmn-neutral-800,
    900: $flwmn-neutral-900,
  ),
  --flwmn-success: (
    default: $flwmn-success,
    50: $flwmn-success-50,
  ),
  --flwmn-warning: (
    default: $flwmn-warning,
    50: $flwmn-warning-50,
    100: $flwmn-warning-100,
  ),
  --flwmn-error: (
    default: $flwmn-error,
    50: $flwmn-error-50,
  ),
  --flwmn-info: (
    default: $flwmn-info,
    50: $flwmn-info-50,
  ),
);
