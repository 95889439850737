.flwmn-time-picker {
  --body-background-color: #fff;
  --primary-font-family: 'Greycliff CF', sans-serif;
  --button-color: var(--flwmn-primary-color);
  --dial-active-color: #fff;
  --dial-inactive-color: rgba(255, 255, 255, 0.5);
  --dial-background-color: var(--flwmn-primary-color);
  --dial-editable-active-color: var(--flwmn-primary-color);
  --dial-editable-background-color: #fff;
  --clock-face-time-active-color: #fff;
  --clock-face-time-inactive-color: #6c6c6c;
  --clock-face-inner-time-inactive-color: #929292;
  --clock-face-time-disabled-color: #c5c5c5;
  --clock-face-background-color: #f0f0f0;
  --clock-hand-color: var(--flwmn-primary-color);
  z-index: 10000 !important;

  .timepicker-dial__control {
    font-size: 3.13rem !important;
    color: var(--dial-inactive-color) !important;
    text-overflow: unset !important;

    &.active {
      color: var(--dial-active-color) !important;
    }
  }
}
