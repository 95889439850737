.general-loader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 999;
  -webkit-animation: fadeIn 0.25s ease-in-out;
  animation: fadeIn 0.25s ease-in-out;

  .general-loader-backdrop {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  &.webapp-view {
    position: absolute;
    // margin: -1rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .general-loader-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-animation: fadeIn 0.45s ease-in-out;
    animation: fadeIn 0.45s ease-in-out;
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.general-progress-bar-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
