@use '../abstracts/media-query' as *;

.flwmn-custom-container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  @include flwmn-media-query-min(flwmn-sm) {
    max-width: 33.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include flwmn-media-query-min(flwmn-md) {
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include flwmn-media-query-min(flwmn-lg) {
    max-width: 81.875rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
