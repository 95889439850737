@use 'theme' as *;

.flwmn-text-primary {
  color: var(--flwmn-primary-color) !important;
}

.flwmn-text-accent {
  color: var(--flwmn-accent-color) !important;
}

.flwmn-text-black {
  color: var(--flwmn-black) !important;
}

.flwmn-text-white {
  color: var(--flwmn-white) !important;
}

.flwmn-text-neutral {
  color: var(--flwmn-neutral) !important;
}

.flwmn-text-neutral-500 {
  color: var(--flwmn-neutral-500) !important;
}

.flwmn-text-error {
  color: var(--flwmn-error) !important;
}

.flwmn-text-warning {
  color: var(--flwmn-warning) !important;
}

.flwmn-text-success {
  color: var(--flwmn-success) !important;
}

// .flwmn-text {
//   border: none !important;
//   background: inherit !important;
// }

.flwmn-text-linear-gradient {
  background: var(--flwmn-linear-gradient) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.flwmn-text-linear-gradient2 {
  background: var(--flwmn-linear-gradient2) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.flwmn-bg-primary {
  background: var(--flwmn-primary-color) !important;
}

.flwmn-bg-linear-gradient {
  background: var(--flwmn-linear-gradient) !important;
}

.flwmn-pointer {
  cursor: pointer;
}

.flwmn-text-capitalize {
  text-transform: capitalize;
}

.flwmn-text-uppercase {
  text-transform: uppercase;
}

.flwmn-divider {
  width: 100%;
  border: 0.0625rem solid var(--flwmn-neutral-100);
  border-radius: 0.3125rem;
  margin: 0.5rem 0;
}

.status {
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 400;
  border-radius: 0.3125rem;
  padding: 0rem 0.625rem;
  line-height: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.primary {
  background-color: var(--flwmn-primary-color-100);
  color: var(--flwmn-primary-color);
}

.success {
  background-color: var(--flwmn-success-50);
  color: var(--flwmn-success);
}

.warning {
  background-color: var(--flwmn-warning-50);
  color: var(--flwmn-warning);
}

.neutral {
  background-color: var(--flwmn-neutral-100);
  color: var(--flwmn-neutral);
}

.error {
  background-color: var(--flwmn-error-50);
  color: var(--flwmn-error);
}

.space-fill {
  background: transparent;
  padding: 1rem;
}
