@use '../abstracts/theme' as *;

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.3125rem 0.75rem;
  border-radius: 0.25rem;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  gap: 0.25rem;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    background: var(--flwmn-neutral-200);
    color: var(--flwmn-neutral-400);
    border-color: var(--flwmn-neutral-200);
    opacity: 1;
    cursor: not-allowed;

    svg {
      path {
        stroke: var(--flwmn-neutral-400);
      }
    }
  }
}

.flwmn-btn-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.flwmn-btn-md {
  font-size: 1rem !important;
  padding: 0.375rem 1rem !important;
}

.flwmn-btn-lg {
  font-size: 1.125rem !important;
  padding: 0.4375rem 1.25rem !important;
}

.flwmn-btn-xl {
  font-size: 1.25rem !important;
  padding: 0.75rem 1.5rem !important;
}

.flwmn-btn-full {
  width: 100%;
}

.flwmn-btn-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}

.flwmn-btn-action {
  border: 0.0625rem solid var(--flwmn-neutral-100);
  border-radius: 0.25rem;
  padding: 0.4375rem 0.75rem;
  font-size: 0.75rem;
  white-space: nowrap;
}

.flwmn-btn-primary-text {
  background-color: inherit;
  color: var(--flwmn-primary-color);

  &:hover {
    color: var(--flwmn-primary-color-400);
  }

  &:focus {
    color: var(--flwmn-primary-color-700);
  }

  &.disabled,
  &:disabled {
    background: inherit;
    color: var(--flwmn-neutral-400) !important;
    border: none;
    opacity: 1;
    cursor: not-allowed;

    svg {
      path {
        stroke: var(--flwmn-neutral-400);
      }
    }
  }
}

.flwmn-btn-primary {
  background-color: var(--flwmn-primary-color);
  color: var(--flwmn-white);

  &:hover {
    background-color: var(--flwmn-primary-color-400);
    color: var(--flwmn-white);
  }

  &:focus {
    background-color: var(--flwmn-primary-color-700);
    color: var(--flwmn-white);
  }
}

.flwmn-btn-primary-outline {
  color: var(--flwmn-primary-color);
  border: 0.09375rem solid var(--flwmn-primary-color);
  // border: 0.0625rem solid var(--flwmn-primary-color);

  &:hover {
    color: var(--flwmn-primary-color);
    border-color: var(--flwmn-primary-color);
  }

  &:focus {
    color: var(--flwmn-primary-color);
  }
}

.flwmn-btn-primary-neutral {
  color: var(--flwmn-primary-color);
  border: 0.0625rem solid var(--flwmn-neutral-100);
  box-shadow: 2px 4px 10px 0px #00143d0d;
  border-radius: 0.3125rem;
  padding: 0.5rem;

  &:hover {
    color: var(--flwmn-primary-color);
  }

  &:focus {
    color: var(--flwmn-primary-color);
  }
}

.flwmn-btn-primary-dark {
  background-color: var(--flwmn-primary-color-900);
  color: var(--flwmn-white);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--flwmn-primary-color-900) !important;
    color: var(--flwmn-white) !important;
    border-color: var(--flwmn-primary-color-900) !important;
  }
}

.flwmn-btn-primary-dark-outline {
  color: var(--flwmn-primary-color-900);
  border: 0.09375rem solid var(--flwmn-primary-color-900);
  background-color: var(--flwmn-white);

  &:hover,
  &:focus,
  &:active {
    color: var(--flwmn-primary-color-900) !important;
    border: 0.09375rem solid var(--flwmn-primary-color-900) !important;
    background-color: var(--flwmn-white) !important;
  }
}

.flwmn-btn-primary-alt {
  background-color: var(--flwmn-primary-color-50);
  color: var(--flwmn-primary-color);
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: var(--flwmn-primary-color-50);
    color: var(--flwmn-primary-color);
  }
}

.flwmn-btn-accent {
  background-color: var(--flwmn-accent-color);
  color: var(--flwmn-white);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--flwmn-accent-color-600) !important;
    color: var(--flwmn-white) !important;
    border-color: unset !important;
  }
}

.flwmn-btn-accent-outline {
  color: var(--flwmn-accent-color);
  // color: var(--flwmn-accent-color-900);
  border: 0.09375rem solid var(--flwmn-accent-color);
  // border: 0.0625rem solid var(--flwmn-accent-color);
  background-color: var(--flwmn-accent-color-50);

  &:hover,
  &:focus,
  &:active {
    color: var(--flwmn-accent-color) !important;
    // color: var(--flwmn-accent-color-900) !important;
    border: 0.09375rem solid var(--flwmn-accent-color) !important;
    // border: 0.0625rem solid var(--flwmn-accent-color) !important;
    background-color: var(--flwmn-accent-color-50) !important;
  }
}

.flwmn-btn-accent-alt {
  background-color: var(--flwmn-accent-color-50);
  color: var(--flwmn-accent-color);
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: var(--flwmn-accent-color-50);
    color: var(--flwmn-accent-color);
  }
}

.flwmn-btn-warning {
  background-color: var(--flwmn-error-50);
  color: var(--flwmn-error);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--flwmn-error) !important;
    color: var(--flwmn-white) !important;
  }
}

.flwmn-btn-danger {
  background-color: var(--flwmn-error);
  color: var(--flwmn-white);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--flwmn-error) !important;
    color: var(--flwmn-white) !important;
  }
}

.flwmn-btn-danger-outline {
  // color: var(--flwmn-error);
  border: 0.09375rem solid var(--flwmn-error);

  &:hover {
    color: var(--flwmn-error);
  }

  &:focus {
    color: var(--flwmn-error);
  }
}

.flwmn-btn-danger-alt {
  background-color: var(--flwmn-error-50);
  color: var(--flwmn-error);
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: var(--flwmn-error-50);
    color: var(--flwmn-error);
  }
}

.flwmn-btn-neutral {
  color: var(--flwmn-neutral);
  border: 0.09375rem solid var(--flwmn-neutral-100);
  // border: 0.0625rem solid var(--flwmn-neutral-100);
  background-color: var(--flwmn-neutral-50);

  &:hover,
  &:focus {
    background-color: var(--flwmn-neutral-50);
    color: var(--flwmn-neutral);
  }
}

.flwmn-btn-neutral-outline {
  color: var(--flwmn-neutral-700);
  border: 0.0625rem solid var(--flwmn-neutral-200);

  &:hover,
  &:focus {
    color: var(--flwmn-neutral-800);
  }
}

.flwmn-btn-linear-gradient {
  color: var(--flwmn-white);
  background: var(--flwmn-linear-gradient);

  &:hover,
  &:active,
  &:focus {
    color: var(--flwmn-white);
    background: var(--flwmn-linear-gradient);
  }
}

.flwmn-btn-white {
  background-color: var(--flwmn-white);
  color: var(--flwmn-primary-color);
  font-weight: 600;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--flwmn-white) !important;
    color: var(--flwmn-primary-color) !important;
    border-color: unset !important;
  }
}

.flwmn-btn-white-outline {
  color: var(--flwmn-white);
  border: 0.09375rem solid var(--flwmn-white);
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: var(--flwmn-white) !important;
    border: 0.09375rem solid var(--flwmn-white) !important;
    background-color: transparent !important;
  }
}

.flwmn-btn-success {
  background-color: var(--flwmn-success);
  color: var(--flwmn-white);

  &:hover,
  &:focus {
    background-color: var(--flwmn-success);
    color: var(--flwmn-white);
  }
}

.flwmn-btn-success-outline {
  color: var(--flwmn-success);
  border: 0.09375rem solid var(--flwmn-success);

  &:hover,
  &:focus {
    color: var(--flwmn-success);
  }
}

.flwmn-drive-filter-item {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--flwmn-neutral-100);
  padding: 0.25rem 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-flex-wrap: wrap;

  &.active {
    border: 0.1rem solid var(--flwmn-primary-color-100);
    background: var(--flwmn-primary-color-50);
  }

  .selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .icon {
    height: 1rem;
    width: 1rem;
  }

  .mat-option {
    font-size: 1.125rem;
  }

  .custom-caret {
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.5rem);
    pointer-events: none;
  }
}
