@use '../abstracts/theme' as *;

.flwmn-badge {
  height: 1rem;
  min-width: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.1875rem 0.5rem;
  border-radius: 0.25rem;

  .flwmn-caption {
    color: var(--flwmn-white);
  }

  &.error {
    color: var(--flwmn-white) !important;
    background: var(--flwmn-error);
  }

  &.success {
    color: var(--flwmn-white) !important;
    background: var(--flwmn-success);
  }

  &.warning {
    color: var(--flwmn-white) !important;
    background: var(--flwmn-warning);
  }

  &.info {
    color: var(--flwmn-white) !important;
    background: var(--flwmn-info);
  }

  &.neutral {
    background-color: var(--flwmn-neutral-100);
    color: var(--flwmn-neutral);
  }

  &.primary {
    background-color: var(--flwmn-primary-color-100);
    color: var(--flwmn-primary-color);
  }
}

.flwmn-badge-rounded {
  height: 1.2rem;
  min-width: 1.2rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.2rem;
  text-align: center;
  font-size: 0.825rem;

  &.primary {
    background-color: var(--flwmn-primary-color);
    color: var(--flwmn-white);
  }

  &.neutral {
    background-color: var(--flwmn-neutral-100);
    color: var(--flwmn-neutral);
  }
}

// .status {
//   cursor: pointer;
//   font-size: 0.75rem;
//   font-weight: 400;
//   border-radius: 0.3125rem;
//   padding: 0.3rem 1rem;
//   line-height: 2rem;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;

//   .warning {
//     background-color: var(--flwmn-warning-50);
//     color: var(--flwmn-warning);
//   }

//   .inactive {
//     background-color: var(--flwmn-neutral-100);
//     color: var(--flwmn-neutral);
//   }

//   .primary {
//     background-color: var(--flwmn-primary-color-100);
//     color: var(--flwmn-primary-color);
//   }

//   .failed {
//     background-color: var(--flwmn-error-50);
//     color: var(--flwmn-error);
//   }

//   .active {
//     background-color: var(--flwmn-primary-color-100);
//     color: var(--flwmn-primary-color);
//   }
// }
