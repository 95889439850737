@use '../abstracts/theme' as *;

.mat-tab-header {
  border-bottom: none !important;
}

.mat-tab-labels {
  gap: 1.375rem !important;
}

.mat-tab-label,
.mat-tab-link {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  color: var(--flwmn-neutral-400) !important;
  opacity: 1 !important;
}

.mat-tab-label {
  padding: 0rem !important;
  height: 2.5rem !important;
  padding: 0rem 0.09375rem !important;
  min-width: 3.125rem !important;
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-transition: color 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
  -o-transition: color 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
  transition: color 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
}

.mat-tab-label-active {
  color: var(--flwmn-primary-color) !important;
}

.mat-ink-bar {
  height: 0.25rem !important;
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
}

.flwmn-tabs {
  .mat-tab-labels {
    gap: 0.5rem !important;
  }

  .mat-tab-header {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    gap: 0.25rem;
    background-color: var(--flwmn-neutral-50);
    padding: 0.25rem;
    border-radius: 0.25rem !important;
  }

  &.tabs-bg-none {
    .mat-tab-header {
      background: none !important;
      padding: 0 !important;
    }
  }

  .mat-tab-label,
  .mat-tab-link {
    min-width: 10rem !important;
    height: 2rem;
    font-size: 0.875rem !important;
    color: var(--flwmn-neutral-500) !important;
    border-radius: 0.25rem !important;
    padding: 0.25rem 0.625rem;
    z-index: 5;

    .mat-tab-label-content {
      img,
      mat-icon,
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .mat-tab-label-active {
    color: var(--flwmn-primary-color) !important;
    background-color: var(--flwmn-primary-color-50) !important;
  }

  .mat-tab-body {
    padding-block: 1rem;
  }

  .mat-ink-bar {
    position: absolute;
    z-index: 0;
    inset: 0;
    height: 100% !important;
    background-color: var(--flwmn-white) !important;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  }
}
