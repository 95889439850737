@use '@angular/material' as mat;

@use '../abstracts/colors' as *;

@include mat.core();

/****************************** Default Palette *****************************/

$Flwmn-default-theme-primary: mat.define-palette(
  $mat-flwmn-default-theme-primary-map,
  500
);
$Flwmn-default-theme-accent: mat.define-palette(
  $mat-flwmn-default-theme-accent-map,
  500
);
$Flwmn-default-theme-warn: mat.define-palette(
  $mat-flwmn-default-theme-warn-map,
  500
);

/****************************************************************************/

/****************************** Webapp Palette ******************************/

$Flwmn-theme1-primary: mat.define-palette($mat-flwmn-theme1-primary-map, 500);
$Flwmn-theme2-primary: mat.define-palette($mat-flwmn-theme2-primary-map, 500);
$Flwmn-theme3-primary: mat.define-palette($mat-flwmn-theme3-primary-map, 500);
$Flwmn-theme4-primary: mat.define-palette($mat-flwmn-theme4-primary-map, 500);
$Flwmn-theme5-primary: mat.define-palette($mat-flwmn-theme5-primary-map, 500);
$Flwmn-theme6-primary: mat.define-palette($mat-flwmn-theme6-primary-map, 500);
$Flwmn-theme7-primary: mat.define-palette($mat-flwmn-theme7-primary-map, 500);

$Flwmn-theme1-accent: mat.define-palette($mat-flwmn-theme1-accent-map, 500);
$Flwmn-theme2-accent: mat.define-palette($mat-flwmn-theme2-accent-map, 500);
$Flwmn-theme3-accent: mat.define-palette($mat-flwmn-theme3-accent-map, 500);
$Flwmn-theme4-accent: mat.define-palette($mat-flwmn-theme4-accent-map, 500);
$Flwmn-theme5-accent: mat.define-palette($mat-flwmn-theme5-accent-map, 500);
$Flwmn-theme6-accent: mat.define-palette($mat-flwmn-theme6-accent-map, 500);
$Flwmn-theme7-accent: mat.define-palette($mat-flwmn-theme7-accent-map, 500);

$Flwmn-warn: mat.define-palette($mat-flwmn-warn-map, 500);

/****************************************************************************/

$Flwmn-typography: mat.define-typography-config(
  $font-family: 'Greycliff CF, sans-serif',
);

/********************************** Default *********************************/

$Flwmn-default-theme-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-default-theme-primary,
      accent: $Flwmn-default-theme-accent,
      warn: $Flwmn-default-theme-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-default-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-default-theme-primary,
      accent: $Flwmn-default-theme-accent,
      warn: $Flwmn-default-theme-warn,
    ),
    typography: $Flwmn-typography,
  )
);

/****************************************************************************/

/********************************** Webapp **********************************/
$Flwmn-theme1-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme1-primary,
      accent: $Flwmn-theme1-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme1-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme1-primary,
      accent: $Flwmn-theme1-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme2-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme2-primary,
      accent: $Flwmn-theme2-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme2-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme2-primary,
      accent: $Flwmn-theme2-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme3-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme3-primary,
      accent: $Flwmn-theme3-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme3-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme3-primary,
      accent: $Flwmn-theme3-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme4-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme4-primary,
      accent: $Flwmn-theme4-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme4-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme4-primary,
      accent: $Flwmn-theme4-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme5-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme5-primary,
      accent: $Flwmn-theme5-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme5-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme5-primary,
      accent: $Flwmn-theme5-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme6-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme6-primary,
      accent: $Flwmn-theme6-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme6-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme6-primary,
      accent: $Flwmn-theme6-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme7-light: mat.define-light-theme(
  (
    color: (
      primary: $Flwmn-theme7-primary,
      accent: $Flwmn-theme7-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

$Flwmn-theme7-dark: mat.define-dark-theme(
  (
    color: (
      primary: $Flwmn-theme7-primary,
      accent: $Flwmn-theme7-accent,
      warn: $Flwmn-warn,
    ),
    typography: $Flwmn-typography,
  )
);

/****************************************************************************/

/* This will disable the style duplication warning */
mat.$theme-ignore-duplication-warnings: true;
/***************************************************/

/********************************** Default *********************************/

.flwmn-default-theme {
  // &.light {
  @include mat.all-component-themes($Flwmn-default-theme-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme1-dark);
  // }
}

/****************************************************************************/

/********************************** Webapp **********************************/

.theme1 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme1-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme1-dark);
  // }
}

.theme2 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme2-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme2-dark);
  // }
}

.theme3 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme3-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme3-dark);
  // }
}

.theme4 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme4-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme4-dark);
  // }
}

.theme5 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme5-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme5-dark);
  // }
}

.theme6 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme6-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme6-dark);
  // }
}

.theme7 {
  // &.light {
  @include mat.all-component-themes($Flwmn-theme7-light);
  // }

  // &.dark {
  //   @include mat.all-component-themes($Flwmn-theme7-dark);
  // }
}

// @include angular-material-typography($Flwmn-typography);

/****************************************************************************/
