.flwmn-option-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  line-height: 1;
  padding-block: 0.25rem;

  .avatar-icon {
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 2.5rem;
    text-align: center;
    line-height: 1.75rem;
    background-color: var(--flwmn-primary-color);
    color: var(--flwmn-white);
  }

  .flwmn-option-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

.flwmn-options-selected,
.flwmn-list {
  padding: 0;

  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    line-height: 1;
    padding: 0.25rem 0.75rem;

    &:not(:last-child) {
      border-bottom: 0.0625rem solid var(--flwmn-neutral-100);
      margin-bottom: 0.5rem;
    }

    .avatar-icon {
      display: block;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 2.5rem;
      text-align: center;
      line-height: 1.75rem;
      background-color: var(--flwmn-primary-color);
      color: var(--flwmn-white);
    }

    .flwmn-option-content {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    .action-container {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      .close-icon {
        width: 1.25rem;
        height: 1.25rem;
        background: none;
        border: none;
        display: grid;
        place-content: center;
      }
    }
  }
}
