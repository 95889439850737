.flwmn-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #ffffff !important;
  }

  &-primary {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: var(--flwmn-primary-color) !important;
    }
  }

  &-neutral {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: var(--flwmn-neutral-400) !important;
    }
  }

  &-success {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: var(--flwmn-success) !important;
    }
  }

  &-warning {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: var(--flwmn-warning) !important;
    }
  }

  &-error {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: var(--flwmn-error) !important;
    }
  }
}
