.cusDropdown {
  position: static;
  pointer-events: none;

  .clickable {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    pointer-events: all;
  }

  .dropped {
    top: 100%;
    pointer-events: all;
    background: #fff;
    box-shadow: 0 0 15px #0000001a;
    transform-origin: 50% 0;
    transform: scaleY(0);
    transition: 0.3s ease;
    border-radius: 10px;
    padding: 10px 0;
    position: absolute;
    width: max-content;

    &.right {
      right: 0;
      left: unset;
    }

    &.top {
      bottom: 100%;
      top: unset;
      transform-origin: 50% 100%;
    }

    &.dropShow {
      transform: scaleY(1);
      z-index: 20;
    }

    .dropItem {
      cursor: pointer;
      pointer-events: all;
      padding: 8px 15px;

      &:hover {
        background: #0000000d;
      }
    }

    hr {
      margin: 5px 0;
    }

    .dropHead {
      padding: 8px 15px;
    }
  }
}
