@use '../abstracts/media-query' as *;
@use '../abstracts/theme' as *;

.flwmn-dialog-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7 !important;
  // background: var(--flwmn-neutral-800);
  background: var(--flwmn-primary-color-900);
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.flwmn-dialog-backdrop-blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // opacity: 0.7 !important;
  backdrop-filter: blur(0.625rem);
  background: #031e6314;
  z-index: 1000;
  pointer-events: auto;
  // -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.flwmn-dialog-right-pane {
  height: 100vh;
  position: fixed !important;
  right: 0;
}

.flwmn-dialog {
  width: 23.375rem !important;
  // max-width: 29.25rem !important;

  &.flwmn-dialog-md {
    width: 33.75rem !important;
  }

  &.flwmn-dialog-lg {
    width: 43.75rem !important;
  }

  &.flwmn-dialog-lg-1 {
    width: 59.125rem !important;
  }

  &.flwmn-dialog-xl {
    width: 64.375rem !important;
  }

  .close-icon-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3125rem;
    cursor: pointer;

    img {
      -webkit-user-drag: none;
    }
  }

  // @include flwmn-media-query-min(flwmn-xxl) {
  //   width: 450px;
  // }

  @include flwmn-media-query-max(flwmn-xsm) {
    width: 100% !important;
  }
}

.hot-toast-message {
  width: 100% !important;
  display: block !important;
}
